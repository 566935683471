"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const andes_react_1 = require("@yonderland/andes-react");
const common_components_1 = require("@as-react/common-components");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const StoreAPI_1 = require("../../../../api/store/StoreAPI");
const ConfigurationUtil_1 = require("../../../../util/ConfigurationUtil");
const LocalStorageUtil_1 = require("../../../../util/LocalStorageUtil");
const StorageKeysConstants_1 = require("../../../../constants/StorageKeysConstants");
const InStoreFilter = ({ inStoreFilter, activeStoreId, inFilterPopover, handleOpenPopup, updateInStoreFilter, updateStoreId, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const handleFilterChange = () => updateStoreId(inStoreFilter === null || inStoreFilter === void 0 ? void 0 : inStoreFilter.storeId);
    const getStoreInfo = (storeId, servicesApi, requestParams) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        try {
            const store = yield StoreAPI_1.default.getStoreInfoByStoreId(servicesApi, requestParams, storeId);
            updateInStoreFilter === null || updateInStoreFilter === void 0 ? void 0 : updateInStoreFilter(store);
            LocalStorageUtil_1.default.setItem(StorageKeysConstants_1.SELECTED_STORE_STORAGE_KEY, store);
        }
        catch (_a) { }
    });
    (0, react_1.useEffect)(() => {
        if (!activeStoreId || (activeStoreId && activeStoreId === (inStoreFilter === null || inStoreFilter === void 0 ? void 0 : inStoreFilter.storeId))) {
            return;
        }
        getStoreInfo(activeStoreId, servicesEndpoint, defaultRequestParams);
    }, [activeStoreId, servicesEndpoint, defaultRequestParams, inStoreFilter]);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { padding: 2 }, theme: cotopaxi_1.BoxTheme.PRIMARY }, desktop: { spacing: { padding: 1.5 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { inline: true, gutter: inFilterPopover ? cotopaxi_1.GroupGutter.XSMALL : cotopaxi_1.GroupGutter.SMALL, valign: inFilterPopover ? cotopaxi_1.GroupVerticalAlign.TOP : cotopaxi_1.GroupVerticalAlign.CENTER, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { flex: { shrink: 0 }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.STORE, size: cotopaxi_1.IconSize.MEDIUM }) }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginRight: 1.5 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.SMALL, children: !inStoreFilter
                                ? t('product.lister.inStore.checkStore')
                                : inStoreFilter && !activeStoreId
                                    ? t('product.lister.inStore.visibleFrom')
                                    : t('product.lister.inStore.availableFrom') }), inStoreFilter && ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { inline: true, emphasized: true, size: cotopaxi_1.TextSize.SMALL, children: [inStoreFilter.storeName, ' '] })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { noBubble: true, text: inStoreFilter ? ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { emphasized: true, inline: true, size: cotopaxi_1.TextSize.SMALL, children: ["(", t('product.lister.inStore.change'), ")"] })) : (t('product.lister.inStore.chooseStore')), size: cotopaxi_1.ButtonSize.SMALL, variant: cotopaxi_1.ButtonVariant.LINK, onClick: handleOpenPopup })] }), inStoreFilter && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { flex: { shrink: 0 }, children: (0, jsx_runtime_1.jsx)(andes_react_1.Switch, { checked: !!activeStoreId, id: inFilterPopover ? 'in_store_filter_popover' : 'in_store_filter', onChange: handleFilterChange }) }))] }) }));
};
exports.default = InStoreFilter;
