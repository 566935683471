"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const andes_react_1 = require("@yonderland/andes-react");
const common_types_1 = require("@as-react/common-types");
const react_i18next_1 = require("react-i18next");
const StoreFinderForm_1 = require("../../store-finder-form/StoreFinderForm");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const StoreFinderGeneral = ({ isShowStock, isShowStockCheckbox, locationSearchType, valuesForm, handleSubmitLocation, handleChangeStock, handleResetValues, overwriteSubtitle, specialtyType, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.product]);
    const subtitle = overwriteSubtitle !== null && overwriteSubtitle !== void 0 ? overwriteSubtitle : (locationSearchType === common_types_1.LocationSearchType.DEFAULT
        ? `${i18nConstants_1.I18N_NAMESPACES.product}:storeFinder.popup.subtitleDefault`
        : `${i18nConstants_1.I18N_NAMESPACES.product}:storeFinder.popup.subtitleLocation`);
    const switcherText = specialtyType ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [t(`${i18nConstants_1.I18N_NAMESPACES.product}:product.specialty.stores.in.stock.${specialtyType}`, {
                specialty_type: t(`${i18nConstants_1.I18N_NAMESPACES.product}:product.specialty.type.${specialtyType}`),
            }), ' ', (0, jsx_runtime_1.jsx)(andes_react_1.Tooltip, { text: t(`${i18nConstants_1.I18N_NAMESPACES.product}:product.specialty.tooltip.stores.${specialtyType}`), hasCloseBtn: false, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { size: cotopaxi_1.IconSize.MINOR, name: cotopaxi_1.IconName.INFORMATION_OUTLINE }) })] })) : (t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.delivery.stock.only`));
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { paddingLeft: 2, paddingRight: 2, paddingBottom: 2 } }, tablet: { spacing: { paddingLeft: 3, paddingRight: 3 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, children: t(subtitle) }) }), (0, jsx_runtime_1.jsx)(StoreFinderForm_1.StoreFinderForm, { initialValues: valuesForm, onSubmit: handleSubmitLocation, handleResetValues: handleResetValues })] }), isShowStockCheckbox && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                    spacing: {
                        paddingLeft: 2,
                        paddingRight: 2,
                        paddingTop: 1.5,
                        paddingBottom: 1.5,
                        marginBottom: 2,
                    },
                    theme: cotopaxi_1.BoxTheme.TERTIARY,
                }, tablet: { spacing: { paddingLeft: 3, paddingRight: 3 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { inline: true, gutter: cotopaxi_1.GroupGutter.SMALL, align: cotopaxi_1.GroupAlign.SPACE_BETWEEN, valign: cotopaxi_1.GroupVerticalAlign.CENTER, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { color: "B500", type: cotopaxi_1.TextType.CONTENT, children: switcherText }), (0, jsx_runtime_1.jsx)(andes_react_1.Switch, { checked: isShowStock, id: "stores-with-stock", onChange: handleChangeStock })] }) }))] }));
};
exports.default = StoreFinderGeneral;
